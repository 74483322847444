<template>
  <!-- 
    Stop dialog close so users need to click the button to close it,
    otherwise you can generate the credentials and fail to copy them
   -->
  <v-dialog id="dialog" v-model="dialog" max-width="750px" persistent>
     <template v-slot:activator="{ on }">
      <v-btn v-on="on" text class="primary--text">
        <v-icon class="mr-1">add_box</v-icon>
        Upload SSH Key
      </v-btn>
    </template>
    <!-- Show create form -->
    <v-card v-if="!createdUserSSHKey">
      <v-card-title>Upload New SSH Key</v-card-title>
      <v-card-subtitle>
        <div class="mt-4 mb-2">If you don't have a key, use 'ssh-keygen' to generate it:</div>
        <markup
          language="bash"
          code='ssh-keygen -t ed25519'
          :inline="false"
        ></markup>

        <div class="mt-2 mb-2">Alternatively, if you have Synpse CLI installed, run:</div>

        <markup
          language="bash"
          code='synpse ssh-keys configure'
          :inline="false"
        ></markup>

      </v-card-subtitle>
      <v-card-text>
        <form v-on:submit.prevent="createUserSSHKey()">
          <v-text-field
            
            label="Key name" 
            v-model="UserSSHKey.name" 
            />

          <v-textarea
            auto-grow
            outlined
            label="SSH Public key, usually starts with 'ssh-rsa', 'ecdsa'" 
            v-model="UserSSHKey.key" 
          />          
          
          <v-btn v-show="false" hidden type="submit">
          </v-btn>
        </form>
        <div v-show="error">
          <v-alert type="error" outlined>
            {{ error }}
          </v-alert>
        </div>
      </v-card-text>
 
      <v-card-actions>
        <v-btn text v-on:click.native="resetForm()" class="primary--text">Close</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="!createdUserSSHKey" text :disabled="loading" :loading="loading" v-on:click.native.stop="createUserSSHKey()" class="primary">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="stylus">
  .addbtn
    margin: 0px
    top: auto
    right: 20px
    bottom: 20px
    left: auto
    position: fixed
    z-index: 1
</style>

<script>
import Markup from '../helpers/Markup';

  export default {
    components: {
      Markup
    },
    data () {
      return {
        dialog: false,

        UserSSHKey: {
          name: '',
          key: ''
        },
      }
    },

    computed: {
      loading() {
        return this.$store.state.iam.loading
      },
      error() {
        return this.$store.state.iam.error
      },
      createdUserSSHKey() {
        return this.$store.state.iam.createdUserSSHKey
      },
      createError() {
        return this.$store.state.iam.createError
      },
    },

    methods: {
      createUserSSHKey () {
        this.$store.dispatch('CreateUserSSHKey', this.UserSSHKey).then(() => {
          if (this.error != null) {
            // got error, don't reset form, let user fix the mistake
            return
          }

          this.$store.dispatch('ListUserSSHKeys')
          this.resetForm()
        })
      },

      resetForm: function () {
        // closing dialog
        this.dialog = false
        this.$store.dispatch('CleanupCreatedUserSSHKey')
        // resetting form
        this.UserSSHKey.key = ''
        this.UserSSHKey.name = ''
      }
    }
  }
</script>
